import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../../../services/shared.service';
import { BasketService, CreateOrderParams } from '@services/basket/basket.service';
import { CurrentOrderGridService } from '../../../services/basket/current.order.grid.service';
import { Subject ,  Observable ,  of, Subscription } from 'rxjs';
import { QuestionDialogService, QuestionDialogResult } from '../../../components/questionDialogComponent/question.dialog.service';
import { OrderDialogService } from '@components/order/orderDialogComponent/order.dialog.service';
import { OrderDialogMode, OrderDialogResult, OrderDialogResultState } from '@components/order/orderDialogComponent/order.dialog.model';
import { ToastService } from '../../../services/toastService/toast.service';
import { CustomerStoresAvailabilityDialogService } from '../customerStoresAvailabilityComponent/customer.stores.availability.dialog.service';
import { BasketItem, BasketItemsResponse, AvailabilityState } from 'app/model/basket.model';
import { BulkItemsUpdateResponse, ExportObj, TableBulk, TableBulkTypes } from '@app/model/table.model';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ProjectPriceListService } from '@app/components/project-price-lists/project-price-lists.service';
import { ProjectPriceListsUserPreferencePrefix } from '@app/components/project-price-lists/project-price-lists.types';

enum ComponentModes {
    Customer,
    Subcustomer
}

@Component({
    selector: 'app-current-order-products-and-detail',
    templateUrl: 'current.order.products.and.detail.component.html',
    providers: [ProjectPriceListService],
})
export class CurrentOrderProductsAndDetailComponent implements OnInit {
    @Input() invalidItemsSubject: Subject<any>;
    @Input() filterInvalidSubject: Subject<any>; // to indicate to set filter to invalid items only

    componentMode: ComponentModes;
    public componentModes = ComponentModes;
    tableNamePrefix = 'ProductsOfOrder';
    totalRows: number;
    filterItems: any[];
    subjectRefresh: Subject<any>;
    subjectReloadCurrent: Subject<any>;
    subjectAllProductsOfCurrentOrderSelected: Subject<any>;
    subjectDeleteSelected: Subject<any>;
    public bulk: TableBulk;
    public exportObj: ExportObj;

    busy: boolean;
    orderBusy: boolean;

    totalWeightAll = 0;
    totalPriceAll = 0;
    totalAmountOrderedAll = 0;
    totalWeightVisible = 0;
    totalPriceVisible = 0;
    totalAmountOrderedVisible = 0;
    totalWeightSelected = 0;
    totalPriceSelected = 0;
    totalAmountOrderedSelected = 0;

    selectedCount = 0;

    totalInvalidCount = 0;

    lastOrderNumberCustomer = '';
    lastOrderNote = '';

    initialized: boolean; // to be sure properties are initialized e.g. user.preferences['productsOfCurrentOrderSelection'] then show table

    showGeneralProjectPriceListMessage = false;

    dataOfCurrentOrder; // current data of current order

    industrialsSubject: Subject<any> = new Subject();
    private subscriptions: Subscription[] = [];

    constructor(private router: Router,
        private route: ActivatedRoute,
        public sharedService: SharedService,
        private basketService: BasketService,
        public currentOrderGridService: CurrentOrderGridService,
        private questionDialogService: QuestionDialogService,
        private orderDialogService: OrderDialogService,
        private toastService: ToastService,
        private customerStoresAvailabilityDialogService: CustomerStoresAvailabilityDialogService,
        public projectPriceListService: ProjectPriceListService,
    ) {
        const this_ = this;
        this.componentMode = this.sharedService.hasPermission('preorders', 'POST')
            ? ComponentModes.Subcustomer : ComponentModes.Customer;

        this.subjectRefresh = new Subject<any>();
        this.subjectReloadCurrent = basketService.getReloadCurrentSubject();
        this.subjectAllProductsOfCurrentOrderSelected = currentOrderGridService.getAllProductsOfCurrentOrderSelectedSubject();
        this.subjectDeleteSelected = new Subject<any>();

        this.getBasketItems = this.getBasketItems.bind(this);
        this.getProductsOfCurrentOrderGridOptions = this.getProductsOfCurrentOrderGridOptions.bind(this);
        this.getProductsOfCurrentOrderColumns = this.getProductsOfCurrentOrderColumns.bind(this);
        this.getProductsOfCurrentOrderColumnsDefs = this.getProductsOfCurrentOrderColumnsDefs.bind(this);
        this.setBulk = this.setBulk.bind(this);
        this.bulkFnCorrectAmountOrdered = this.bulkFnCorrectAmountOrdered.bind(this);

        this.projectPriceListService.init({
            userPreferencePrefix: ProjectPriceListsUserPreferencePrefix.Basket,
            showProductSalePriceListCode: true,
        });

        this.subscriptions.push(this.projectPriceListService.onPriceListSelected$.subscribe(() => {
            this.reload();
        }));

        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('ITEMS_IN_CART'),
                export: this.basketService.exportAll.bind(this.basketService)
            };
        });

        if (!this.sharedService.user.preferences.hasOwnProperty('productsOfCurrentOrderSelection')) {
            this.sharedService.user.preferences.productsOfCurrentOrderSelection = { ids: {}, all: false, visible: false };
        }
        this.totalWeightSelected = this.sharedService.user.preferences.productsOfCurrentOrderSelection.totalWeight ? this.sharedService.user.preferences.productsOfCurrentOrderSelection.totalWeight : 0;
        this.totalPriceSelected = this.sharedService.user.preferences.productsOfCurrentOrderSelection.totalValue ? this.sharedService.user.preferences.productsOfCurrentOrderSelection.totalValue : 0;
        this.totalAmountOrderedSelected = this.sharedService.user.preferences.productsOfCurrentOrderSelection.totalAmountOrdered ? this.sharedService.user.preferences.productsOfCurrentOrderSelection.totalAmountOrdered : 0;
        this.selectedCount = this.getSelectedCount();

        // item was selected
        this.currentOrderGridService.getProductOfCurrentOrderSelectedSubject().subscribe(res => {
            const preference = this_.sharedService.user.preferences.productsOfCurrentOrderSelection;
            const rowWeight = res.data.weight * res.data.amountOrdered;
            const rowValue = res.data.unitPrice * res.data.amountOrdered;
            const packingOption = res.data.packingOptions.filter(option => option.packing === res.data.packing)[0];
            const priceCoefficient = (packingOption && packingOption.priceCoefficient) ? packingOption.priceCoefficient : 1;
            if (res.selected) {
                this_.totalWeightSelected = Math.round((this_.totalWeightSelected + rowWeight) * 10000) / 10000;
                this_.totalPriceSelected = Math.round((this_.totalPriceSelected + (rowValue * priceCoefficient)) * 10000) / 10000;
                this_.totalAmountOrderedSelected = this_.totalAmountOrderedSelected + res.data.amountOrdered;
            } else {
                this_.totalWeightSelected = Math.round((this_.totalWeightSelected - rowWeight) * 10000) / 10000;
                this_.totalPriceSelected = Math.round((this_.totalPriceSelected - (rowValue * priceCoefficient)) * 10000) / 10000;
                this_.totalAmountOrderedSelected = this_.totalAmountOrderedSelected - res.data.amountOrdered;
            }
            preference.totalWeight = this_.totalWeightSelected;
            preference.totalValue = this_.totalPriceSelected;
            preference.totalAmountOrdered = this_.totalAmountOrderedSelected;
            localStorage.setItem('user', JSON.stringify(this_.sharedService.user));
            this_.selectedCount = this_.getSelectedCount();
            this.setBulk();
        });

        // items was multiple selected
        this.currentOrderGridService.getAllProductsOfCurrentOrderSelectedSubject().subscribe(res => {
            const preference = this_.sharedService.user.preferences.productsOfCurrentOrderSelection;
            this_.totalWeightSelected = 0;
            this_.totalPriceSelected = 0;
            this_.totalAmountOrderedSelected = 0;
            if (preference.visible) {
                this_.totalWeightSelected = this_.totalWeightVisible;
                this_.totalPriceSelected = this_.totalPriceVisible;
                this_.totalAmountOrderedSelected = this_.totalAmountOrderedVisible;
            }
            if (preference.all) {
                this_.totalWeightSelected = this_.totalWeightAll;
                this_.totalPriceSelected = this_.totalPriceAll;
                this_.totalAmountOrderedSelected = this_.totalAmountOrderedAll;
            }
            preference.totalWeight = this_.totalWeightSelected;
            preference.totalValue = this_.totalPriceSelected;
            preference.totalAmountOrdered = this_.totalAmountOrderedSelected;
            localStorage.setItem('user', JSON.stringify(this_.sharedService.user));
            this_.selectedCount = this_.getSelectedCount();
            this.setBulk();
        });

        // item was updated
        this.currentOrderGridService.getProductOfCurrentOrderUpdatedSubject().subscribe((res) => {
            const preference = this.sharedService.user.preferences.productsOfCurrentOrderSelection;
            this.totalWeightAll = Math.round((this.totalWeightAll + res.rowWeightDif) * 10000) / 10000;
            this.totalPriceAll = Math.round((this.totalPriceAll + res.rowPriceDif) * 10000) / 10000;
            if (preference.ids[res.id]) {
                this.totalWeightSelected = Math.round((this.totalWeightSelected + res.rowWeightDif) * 10000) / 10000;
                this.totalPriceSelected = Math.round((this.totalPriceSelected + res.rowPriceDif) * 10000) / 10000;
                this.totalAmountOrderedSelected = this.totalAmountOrderedSelected + res.amountOrderedDif;
                preference.totalWeight = this.totalWeightSelected;
                preference.totalValue = this.totalPriceSelected;
                preference.totalAmountOrdered = this.totalAmountOrderedSelected;
                localStorage.setItem('user', JSON.stringify(this.sharedService.user));
            }
        });

        this.subjectDeleteSelected.subscribe(res => {
            this_.busy = true;
            this_.basketService.deleteOrderItems(res, this_.route.snapshot.queryParams).subscribe(data => {
                // this.getInvalidItems();
                let filterFound = false;
                if (res.length === 0 || res.length === this_.totalRows) {
                    for (const key in this_.route.snapshot.queryParams) {
                        if (key !== 'orderBy' && key !== 'page') {
                            delete this_.sharedService.lastProductsOfCurrentOrderQueryParams[key];
                            filterFound = true;
                        }
                    }
                    if (filterFound) {
                        delete this_.sharedService.lastProductsOfCurrentOrderQueryParams['page'];
                        this_.router.navigate([], { queryParams: this_.sharedService.lastProductsOfCurrentOrderQueryParams, replaceUrl: true });
                        return;
                    }
                }
                this_.reload();
            }, err => {
                console.log(err);
            });
        });

        // to reload when menu item is clicked but it is already routed to this area
        this.sharedService.subjectSameAreaClicked.subscribe(res => {
            this.subjectRefresh.next();
        });

        this.initialized = true;
    }

    ngOnInit() {
        setTimeout(() => {
            // navigate with orderby parameter
            this.sharedService.navigateWithOrderby(this.getProductsOfCurrentOrderColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastProductsOfCurrentOrderQueryParams);
        }, 0);

        this.filterInvalidSubject.subscribe(() => {
            // delete this.sharedService.lastProductsOfCurrentOrderQueryParams['page'];
            this.router.navigate([], { queryParams: {
                orderBy: this.route.snapshot.queryParams['orderBy'],
                page: 1,
                'valid.operator': 'eq', 'valid.value': '0', 
            }, replaceUrl: true });
            this.subjectRefresh.next();
        });
    }

    // Bulk operations
    private setBulk(): void {
        // const bulkFnUpdate: TableBulkUpdateFn = (items, data) => {
        //     return this.basketService.updateOrderedItems(items, data, Object.assign({}, this.route.snapshot.queryParams));
        // };

        const bulkFnDuplicate = (items) => {
            return this.basketService.duplicateOrderedItems(items)
                .subscribe(data => {
                    this.subjectReloadCurrent.next();
                }, err => console.log(err));
        };

        const bulkFnCorrectDeliveryDate = (items) => {
            return this.questionDialogService.confirm(
                {
                    message: 'CORRECT_DATES',
                    question: 'INVALID_DATES_WILL_BE_CORRECTED',
                    primary: 'YES',
                    secondary: 'NO',
                    suppressQuestionMark: true
                }
            ).subscribe(
                result => {
                    if (result === 'confirm') {
                        return this.basketService.correctDeliveryDatesOrderedItems(items)
                            .subscribe(() => {
                                this.subjectReloadCurrent.next();
                                this.toastService.addSuccess('DELIVERY_DATE_CORRECTED');
                            }, err => console.log(err));
                    } else {
                        return of();
                    }
                }
            );
        };

        const bulkFnCustomerStock = (items) => {
            const itemsToShow = [];
            const selection = this.sharedService.user.preferences.productsOfCurrentOrderSelection;
            if (!selection.all) {
                Object.keys(selection.ids).forEach(id => {
                    if (selection.ids[id] && selection.ids[id].availabilityState === AvailabilityState.PARTIALLY_FULFILLED) {
                        itemsToShow.push(id);
                    }
                });
            }
            this.customerStoresAvailabilityDialogService.confirm({ ids: itemsToShow });

            return new Observable((observer) => {
                observer.next();
                observer.complete();
            });
        };

        const d = new Date();

        this.bulk = {
            update: this.bulkUpdateFn.bind(this),
            items: [
                { 
                    id: 'duplicate', name: 'DUPLICATE', value: null, type: TableBulkTypes.button, 
                    iconClass: 'fa fa-clone', click: bulkFnDuplicate 
                },
                ...(this.sharedService.params.features.customer.customerStock && this.componentMode === ComponentModes.Customer &&
                    this.isSelectedPartiallyFullfilled()
                    ? [{
                        id: 'customerStock', name: 'CUSTOMER_DISTRIBUTOR_STORES_AVAILABILITY', value: null, type: TableBulkTypes.button, iconClass: 'fa fa-database',
                        click: bulkFnCustomerStock
                    }]
                    : []
                ),
                { 
                    id: 'deliveryDate', name: 'DATE_OF_DELIVERY', value: { date: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() } }, 
                    type: TableBulkTypes.date 
                },
                ...(this.isSelectedInvalidAmountOrdered()
                    ? [{
                        id: 'correctAmountOrdered', name: 'CORRECT_AMOUNT_ORDERED', value: null, type: TableBulkTypes.button, iconClass: 'fa fa-archive',
                        click: this.bulkFnCorrectAmountOrdered
                    }]
                    : []
                ),
                ...(this.isSelectedInvalidDate()
                    ? [
                        { id: 'correctDeliveryDate', name: 'CORRECT_DELIVERY_DATE', value: null, type: TableBulkTypes.button, iconClass: 'fa fa-calendar',
                        click: bulkFnCorrectDeliveryDate
                    }]
                    : []
                ),
                {
                    id: 'packing', name: 'SET_PACKING_FORM', type: TableBulkTypes.select, value: null,
                    values: [...this.currentOrderGridService.ALL_PACKINGS], buttonLabel: 'SET_PACKING_FORM',
                    itemUpdateFn: (itemIds, newValues) => this.bulkFnSetPacking(itemIds, newValues)
                }
            ]
        }
    }

    private bulkUpdateFn(itemIds: string[], newValues): Observable<BulkItemsUpdateResponse<BasketItem>> {
        return this.basketService.updateOrderedItems(itemIds, newValues, Object.assign({}, this.route.snapshot.queryParams));
    };

    private isSelectedPartiallyFullfilled(): boolean {
        const selection = this.sharedService.user.preferences.productsOfCurrentOrderSelection;
        return selection.all ||
            Object.keys(selection.ids).some(id =>
                selection.ids[id] && selection.ids[id].availabilityState === AvailabilityState.PARTIALLY_FULFILLED
            );
    }

    private isSelectedInvalidDate(): boolean {
        const selection = this.sharedService.user.preferences.productsOfCurrentOrderSelection;
        let invalidCount = 0;
        if (!selection.all) {
            invalidCount = Object.keys(selection.ids).reduce((acc, id) => acc + (this.basketService.isItemDateValid(selection.ids[id]) ? 0 : 1), 0);
        } else {
            invalidCount = this.totalInvalidCount;
        }
        return invalidCount > 0;
    }

    private isSelectedInvalidAmountOrdered(): boolean {
        const selection = this.sharedService.user.preferences.productsOfCurrentOrderSelection;
        let invalidCount = 0;
        if (!selection.all) {
            invalidCount = Object.keys(selection.ids).reduce((acc, id) => acc + (this.basketService.isItemAmountOrderedValid(selection.ids[id]) ? 0 : 1), 0);
        } else {
            invalidCount = this.totalInvalidCount;
        }
        return invalidCount > 0;
    }

    private bulkFnCorrectAmountOrdered(itemIds: string[]): void {
        this.questionDialogService.confirm(
            {
                message: 'CORRECT_AMOUNT_ORDERED',
                question: 'INVALID_AMOUNTS_ORDERED_WILL_BE_CORRECTED',
                primary: 'YES',
                secondary: 'NO',
                suppressQuestionMark: true
            }
        ).subscribe(
            result => {
                if (result === 'confirm') {
                    this.basketService.correctAmountOrdered(itemIds)
                        .subscribe(() => {
                            this.subjectReloadCurrent.next();
                            this.toastService.addSuccess('AMOUNT_ORDERED_CORRECTED');
                        }, err => console.log(err));
                }
            }
        );
    }

    private bulkFnSetPacking(itemIds: string[], newValues: {packing: string}): Observable<any> {
        return this.questionDialogService.confirm(
            {
                message: 'SET_PACKING_FORM',
                question: 'SET_PACKING_FORM_INFO_QUESTION',
                primary: 'YES',
                secondary: 'NO',
                suppressQuestionMark: true
            }
        ).pipe(
            switchMap(result => {
                if (result === QuestionDialogResult.Confirm) {
                    return this.bulkUpdateFn(itemIds, newValues);
                } else {
                    return of(null);
                }
            }),
            tap((updateResult: BulkItemsUpdateResponse<BasketItem> | null) => {
                if (updateResult !== null) {
                    if (updateResult.errors && updateResult.errors.length > 0) {
                        this.toastService.addError(updateResult.errors.length + ' ' + 
                            this.sharedService.translateService.instant('ITEMS_OF_X_ITEMS') + ' ' + 
                            (updateResult.successCount + updateResult.errors.length) + ' ' + 
                            this.sharedService.translateService.instant('ITEMS_OF_X_ITEMS_CANNOT_BE_UPDATED')
                        );
                    }
                }
            }),
            catchError(err => {
                console.error(err);
                return of({});
            })
        );
    }

    getBasketItems(params) {
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };
        if (this.projectPriceListService.isSetNonGeneralProjectPriceList()) {
            query.projectPriceListCode = this.projectPriceListService.currentPriceList.priceListCode;
        }

        this.sharedService.lastProductsOfCurrentOrderQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastProductsOfCurrentOrderQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.basketService.getBasketItems(query)
            .subscribe((data: BasketItemsResponse) => {
                this.dataOfCurrentOrder = data;
                this.totalRows = data.totalCount;
                this.totalWeightAll = Math.round(data.totalWeight * 10000) / 10000;
                this.totalPriceAll = Math.round(data.totalPrice * 10000) / 10000;
                this.totalAmountOrderedAll = data.totalAmountOrdered;
                this.totalWeightVisible = Math.round(data.totalWeightCurrent * 10000) / 10000;
                this.totalPriceVisible = Math.round(data.totalPriceCurrent * 10000) / 10000;
                this.totalAmountOrderedVisible = data.totalAmountOrderedCurrent;
                //       if (this_.sharedService.user.preferences['productsOfCurrentOrderSelection'] && this_.sharedService.user.preferences['productsOfCurrentOrderSelection'].visible) {
                //        this_.totalWeightSelected = this_.totalWeightVisible;
                //        this_.totalPriceSelected = this_.totalPriceVisible;
                //        this_.totalAmountOrderedSelected = this_.totalAmountOrderedVisible;
                //        this_.sharedService.user.preferences['productsOfCurrentOrderSelection'].totalWeight = this_.totalWeightSelected;
                //        this_.sharedService.user.preferences['productsOfCurrentOrderSelection'].totalValue = this_.totalPriceSelected;
                //       }
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getProductsOfCurrentOrderFilterItems();
                }
                this.busy = false;
                params.successCallback(data.rows, data.totalCount);
                this.totalInvalidCount = data.totalInvalidCount;
                this.invalidItemsSubject.next(this.totalInvalidCount);
                this.refreshSelected(data.rows);
                this.setBulk();
                this.selectedCount = this.getSelectedCount();
            }, err => {
                this.handleHttpError(err);
                // also in error to be able to cancel filter
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getProductsOfCurrentOrderFilterItems();
                }
                this.busy = false;
            });
    }


    /**
     * After items reloading it is necessary to refresh rows attributes in a case that
     * it has been changed before the last reload (e.g. bulk operation)
     */
    private refreshSelected(rows: BasketItem[]) {
        const selection = this.sharedService.user.preferences.productsOfCurrentOrderSelection;
        rows.forEach(row => {
            if (row.id in selection.ids) {
                selection.ids[row.id] = Object.assign(selection.ids[row.id], row);
                selection.ids[row.id].valid = row.valid === 1 ? 1 : 0;
            }
        });
    }

    /**
     * If the specified error is 'Unknown projectPriceListCode' error then do not show it but delete that priceList from dropdown and reload the basket items
     */
    handleHttpError(err) {
        const missingProjectPriceListCode = this.getMissingProjectPriceListCode(err);
        if (!missingProjectPriceListCode) {
            console.log(err);
            return;
        }

        this.projectPriceListService.removeProjectPriceList(missingProjectPriceListCode);
        this.projectPriceListService.setGeneralProjectPriceList();
    }

    /**
     * Finds out if the specified error is 'Unknown projectPriceListCode' error
     */
    getMissingProjectPriceListCode(err): string {
        if (err && err.hasOwnProperty('error') && err.error.hasOwnProperty('message')) {
            // 'Unknown projectPriceListCode ASL1'
            const splitted = err.error.message.split(' ');
            if (splitted.length === 3 && splitted[0] === 'Unknown' && splitted[1] === 'projectPriceListCode') {
                return splitted[2];
            }
        }
        return null;
    }

    getProductsOfCurrentOrderGridOptions() {
        return this.currentOrderGridService.getProductsOfCurrentOrderProductGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10));
    }
    getProductsOfCurrentOrderFilterItems() {
        return this.currentOrderGridService.getProductsOfCurrentOrderFilterItems();
    }
    getProductsOfCurrentOrderColumns(forceOriginal?: boolean) {
        return this.currentOrderGridService.getProductsOfCurrentOrderColumns(forceOriginal);
    }

    getProductsOfCurrentOrderColumnsDefs() {
        return this.currentOrderGridService.getProductsOfCurrentOrderColumnDefs();
    }

    getSelectedCount() {
        if (this.sharedService.user.preferences.productsOfCurrentOrderSelection && Object.keys(this.sharedService.user.preferences.productsOfCurrentOrderSelection.ids).length === 0) {
            this.totalWeightSelected = 0;
            this.totalPriceSelected = 0;
            this.sharedService.user.preferences.productsOfCurrentOrderSelection.totalWeight = this.totalWeightSelected;
            this.sharedService.user.preferences.productsOfCurrentOrderSelection.totalValue = this.totalPriceSelected;
            localStorage.setItem('user', JSON.stringify(this.sharedService.user));
        }
        return this.sharedService.user.preferences.productsOfCurrentOrderSelection ?
            Object.keys(this.sharedService.user.preferences.productsOfCurrentOrderSelection.ids).length : 0;
    }

    getEditedCount() {
        return this.currentOrderGridService.productsOfCurrentOrderEditedIds ? Object.keys(this.currentOrderGridService.productsOfCurrentOrderEditedIds).length : 0;
    }

    reload() {
        this.subjectReloadCurrent.next();
    }

    orderAttrChanged(attrName, value) {
        this.sharedService.user.preferences['currentOrder_' + attrName] = value;
        localStorage.setItem('user', JSON.stringify(this.sharedService.user));
    }

    /**
     * Searches row items for industrial packing ('C')
     * @returns true - found some industrial packing, false - did not find
     */
    industrialPacking(): Observable<boolean> {
        return Observable.create((observer) => {
            // Only show the warning for some AS
            if (!this.sharedService.apParams.showIndustrPackingOrderingWarning) {
                observer.next(false);
                return;
            }

            const INDUSTRIAL_PACKING = 'C';
            const currentPageSize = this.sharedService.user.preferences['current-orderTablePageSize'];
            const numOfPages = Math.trunc(this.dataOfCurrentOrder.rows.length / currentPageSize) + 1;
            const selectedIds = Object.keys(this.sharedService.user.preferences.productsOfCurrentOrderSelection.ids);
            const allSelected = this.sharedService.user.preferences.productsOfCurrentOrderSelection.all;

            if (selectedIds.length === 0 || allSelected) {
                if (numOfPages === 1) {
                    const foundIndustrial = this.dataOfCurrentOrder.rows.filter(row => row.packing === INDUSTRIAL_PACKING).length > 0;
                    observer.next(foundIndustrial);
                } else {
                    this.findIndustrialInDb(INDUSTRIAL_PACKING, observer);
                }
            } else {  // some selected
                const rowsWithSelectedIds = selectedIds.reduce((acc, selectedId) => {
                    const rowArr = this.dataOfCurrentOrder.rows.filter(row => row.id === +selectedId);
                    return [...acc, ...(rowArr.length === 1 ? rowArr : [])];
                }, []);

                if (selectedIds.length === rowsWithSelectedIds.length) {
                    const foundIndustrial = rowsWithSelectedIds.filter(row => row.packing === INDUSTRIAL_PACKING).length > 0;
                    observer.next(foundIndustrial);
                } else {
                    this.findIndustrialInDb(INDUSTRIAL_PACKING, observer);
                }
            }
        });
    }

    findIndustrialInDb(packing: string, observer) {
        this.basketService.getBasketItems({ packing }).subscribe(rowsWithIndustrial => {
            const foundIndustrial = rowsWithIndustrial.rows.length > 0;
            observer.next(foundIndustrial);
        },
            error => console.log(error)
        );
    }

    private getSelectedInvalidCount(): number {
        const selection = this.sharedService.user.preferences.productsOfCurrentOrderSelection;
        let invalidCount: number;
        if (!selection.all) {
            invalidCount = Object.keys(selection.ids).reduce((acc, id) => acc + (selection.ids[id].valid ? 0 : 1), 0);
        } else {
            invalidCount = this.totalInvalidCount;
        }
        return invalidCount;
    }

    private getTranslateCode(code: string): string {
        const codeMap = {
            // Customer -> Subcustomer
            ORDER_CREATE_UNABLE_BECAUSE_OF_INVALID_ITEMS: 'PREORDER_CREATE_UNABLE_BECAUSE_OF_INVALID_ITEMS'
        };
        if (!(code in codeMap)) {
            return code;
        } else {
            return this.componentMode === ComponentModes.Customer ? code : codeMap[code];
        }
    }

    /**
     * Shows oder/preorder dialog in 'details' mode to fill details of order/preorder
     */
    public order() {
        // check for invalid items before allowing to make order
        if (!this.isFiltered() && this.totalInvalidCount > 0) {
            const invalidCount = this.selectedCount > 0 ? this.getSelectedInvalidCount() : this.totalInvalidCount;
            if (invalidCount > 0) {
                this.questionDialogService.confirm(
                    {
                        message: this.sharedService.translateService.instant(this.getTranslateCode('ORDER_CREATE_UNABLE_BECAUSE_OF_INVALID_ITEMS')) +
                            ' (' + invalidCount + '). ' + this.sharedService.translateService.instant('INVALID_ITEMS_MARKED_RED_PLEASE_CORRECT'),
                        primary: 'SHOW_INVALID_ITEMS',
                        secondary: 'CANCEL',
                        suppressQuestionMark: true,
                        textClass: 'fnt-orange'
                    }
                ).subscribe(answer => {
                    if (answer === QuestionDialogResult.Confirm) {
                            this.filterInvalidSubject.next();
                    }
                });
                return;
            }
        }

        this.industrialPacking().subscribe(
            hasIdustrials => {
                this.orderDialogService.confirm({
                    mode: OrderDialogMode.Details,
                    data: {
                        orderNumberCustomer: this.lastOrderNumberCustomer,
                        orderNote: this.lastOrderNote, hasIdustrials: hasIdustrials
                    },
                    preorder: this.componentMode === ComponentModes.Subcustomer
                }).subscribe(
                    (res: OrderDialogResult) => {
                        if (res.state === OrderDialogResultState.Order) {
                            this.createOrder(res.data);
                        }
                    }
                );
            },
            error => console.log(error),
        );
    }

    /**
     * Creates new order/preorder
     * @param data - details data object for new order/preorder
     */
    createOrder(data: CreateOrderParams) {
        const ids: string[] = [];
        if (!this.sharedService.user.preferences.productsOfCurrentOrderSelection ||
            !this.sharedService.user.preferences.productsOfCurrentOrderSelection.all) {
            for (const key in this.sharedService.user.preferences.productsOfCurrentOrderSelection.ids) {
                if (this.sharedService.user.preferences.productsOfCurrentOrderSelection.ids[key]) { ids.push(key); }
            }
        }
        const createOrderParams: any = {
            items: ids,
            orderNumberCustomer: data.orderNumberCustomer,
            orderNote: data.orderNote
        };
        if (this.projectPriceListService.isSetNonGeneralProjectPriceList()) {
            createOrderParams.projectPriceListCode = this.projectPriceListService.currentPriceList.priceListCode;
        }

        // save for later if will be needed e.g. after setting deliveryDate when some of dates are invalid
        this.lastOrderNumberCustomer = data.orderNumberCustomer;
        this.lastOrderNote = data.orderNote;

        const createMethod = this.componentMode === ComponentModes.Customer ? 'order' : 'preorder';

        this.basketService[createMethod](createOrderParams)
            .subscribe(result => {
                this.orderBusy = false;
                this.sharedService.user.preferences['currentOrder_' + 'orderNumberCustomer'] = '';
                this.sharedService.user.preferences['currentOrder_' + 'orderNote'] = '';

                const preference = this.sharedService.user.preferences.productsOfCurrentOrderSelection;

                preference.totalWeight = 0;
                preference.totalValue = 0;
                preference.totalAmountOrdered = 0;
                preference.ids = {};
                preference.all = false;
                preference.visible = false;
                this.totalWeightSelected = 0;
                this.totalPriceSelected = 0;
                this.totalAmountOrderedSelected = 0;
                this.selectedCount = 0;
                localStorage.setItem('user', JSON.stringify(this.sharedService.user));
                this.setGeneralProjectPriceList();
                this.reload();

                if (this.componentMode === ComponentModes.Subcustomer) {
                    this.afterOrderCreated();
                    return;
                }
                this.questionDialogService.confirm(
                    {
                        message: 'ORDER_CREATE_SUCCESS',
                        question: 'ORDER_GO_TO_NEW',
                        primary: 'YES',
                        secondary: 'NO',
                    }
                ).subscribe(
                    res => {
                        this.afterOrderCreated();

                        if (res === QuestionDialogResult.Confirm) {
                            this.sharedService.backUrl = 'current-order';
                            this.router.navigate(['/' + this.sharedService.appSettings.language + '/order/' + result.purchaseOrder.id]);
                        }
                    }
                );
            }, err => {
                console.log(err);
                this.orderBusy = false;

                if (err.error.name === 'InvalidOrderItems') {
                    const dataObj = {
                        errorCount: 0,
                        errors: err.error.errors
                    };
                    dataObj.errors.map(error => {
                        dataObj.errorCount += error.count
                    });
                    this.orderDialogService.confirm({
                        mode: OrderDialogMode.Errors,
                        data: dataObj,
                        preorder: this.componentMode === ComponentModes.Subcustomer
                     }).subscribe(
                        (res: OrderDialogResult) => {
                            if (res.state === OrderDialogResultState.ShowInvalidItems) {
                                this.filterInvalidSubject.next();
                            }
                        }
                    );
                } else {
                    this.toastService.addError('ERROR_OCCURED');
                }
            });
    }

    private isFiltered(): boolean {
        const filterAttrs = Object.keys(this.route.snapshot.queryParams).filter(key => key !== 'orderBy' && key !== 'page');
        return filterAttrs.length > 0;
    }

    private afterOrderCreated() {
        this.lastOrderNumberCustomer = '';
        this.lastOrderNote = '';
        this.generalProjectPriceListMessage();
    }

    /**
     * Price list methods
     */
    private setGeneralProjectPriceList(): void {
        if (this.projectPriceListService.isSetNonGeneralProjectPriceList()) {
            this.showGeneralProjectPriceListMessage = true;
        }
        this.projectPriceListService.setGeneralProjectPriceList();
    }

    private generalProjectPriceListMessage(): void {
        if (this.showGeneralProjectPriceListMessage) {
            this.showGeneralProjectPriceListMessage = false;
            this.toastService.addSuccess('PROJECT_PRICE_LIST_SET_TO_GENERAL');
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
