<div class="row-container" fxLayout="column" *ngIf="catalogueMode">

    <div class="row-container" *ngIf="catalogueMode === catalogueModes.CatalogueSale">
        <button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/catalogue">
            <i class="fa fa-arrow-circle-left"></i>
            <a translate>BACK</a>
        </button>
        <h3 class="area-title mt-15">
            <img src="../../assets/images/sale_black.png" style="width: 23px; margin-right: 5px;"/><span translate>PRODUCT_SALE</span>
        </h3>
    </div>

    <div class="row-container" fxLayout="column" fxLayout.gt-sm="row">

        <div fxFlex.gt-sm="280px" class="row-container">
            <div class="new-products" 
                *ngIf="newProductsExist && sharedService.params?.features?.catalogue?.showNewProducts && catalogueMode === catalogueModes.Catalogue">
                <button class="bkg-secondary clickable" (click)="newProductsClicked()">
                    <i class="fa fa-star-o"></i>
                    <a translate>NEW_PRODUCTS</a>
                </button>
            </div>

            <app-accordion-menu 
                [categories]="brandCategories"
                [productionGroup1]="productionGroup1" 
                [productionGroup2]="productionGroup2"
                [catalogueMode]="catalogueMode"
                (onSelect)="onFilter()" ></app-accordion-menu>

            <div class="product-sale" 
                *ngIf="showProductSale">
                <button class="bkg-secondary clickable"
                    routerLink="/{{sharedService.appSettings.language}}/catalogue-sale">
                    <img src="../../assets/images/sale_black.png" style="width: 23px; margin-right: 5px;"/>
                    <a translate>PRODUCT_SALE</a>
                </button>
            </div>
        </div>

        <div fxFlex.gt-sm="100" class="products row-container" *ngIf="!detailId">

            <div class="row-container bkg-gray-darker">
                <span *ngFor="let crumb of breadCrumbs; let isLast = last; let isFirst = first">
                    <span *ngIf="!isFirst" style="margin: 0 5px;">/</span>
                    <span *ngIf="isLast">{{crumb.name}}</span>
                    <a [routerLink]="['/' + sharedService.appSettings.language + '/' + crumb.url]" 
                        [queryParams]="crumb.query" (click)="setPrefAndFilter(crumb)" *ngIf="!isLast">{{crumb.name}}</a>
                </span>
            </div>

            <div *ngIf="showProjectPriceLists">
                <app-project-price-lists></app-project-price-lists>
            </div>

            <div>
                <div class="filter row-container">
                    <app-filter [busy]="busy"
                        [breadCrumbs]="breadCrumbs" 
                        [catalogueMode]="catalogueMode"
                        [forceFilter$]="forceFilter$.asObservable()"
                        (onFilterChanged)="onFilter()"></app-filter>
                </div>

                <ecm-table [dataGetter]="getProducts" 
                            [optionsGetter]="getGridOptions"
                            [columnsGetter]="getColumns"
                            [columnsDefsGetter]="getColumnsDefs"
                            [total]="totalRows"
                            [observableRefresh]="subjectRefresh.asObservable()"
                            [showRefreshButton]="true"
                            [busy]="busy"
                            [exportObj]="exportObj"
                            [exportArray]="exportArray"></ecm-table>
            </div>
        </div>

        <div fxFlex.gt-sm="100" id="detail" class="detail row-container" *ngIf="detailId">
            <app-product-detail 
                [productionGroup1]="productionGroup1" 
                [productionGroup2]="productionGroup2" 
                [detailId]="detailId"
                [catalogueMode]="catalogueMode">
            </app-product-detail>
        </div>
    </div>
</div>