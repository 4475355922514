import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { CatalogueService, CatalogueModes } from '@services/catalogue/catalogue.service';
import { BasketService } from '../../services/basket/basket.service';
import { AsWebLinkTypes, Product, ProductAvailabilityObj } from '../../model/product.model';
import { Location } from '@angular/common';
import { TableService } from '@app/services/table.service';

@Component({
    selector: 'app-product-detail',
    templateUrl: 'product.detail.component.html'
    // styleUrls:  ['./product.detail.component.css']
})
export class ProductDetailComponent implements OnInit {
    @Input() productionGroup1: string;
    @Input() productionGroup2: string;
    @Input() detailId: string;
    @Input() public catalogueMode: CatalogueModes;

    currentQueryParams: any = {};
    product: Product;
    amount: number

    canOpenAvailabilityPopover: boolean;
    public availabilityRowsObj: ProductAvailabilityObj;
    busy: boolean;
    public asProductUrl: string;
    public asWebLinkTypes = AsWebLinkTypes;

    constructor(
        private location: Location,
        private activatedRoute: ActivatedRoute,
        public sharedService: SharedService,
        public catalogueService: CatalogueService,
        private basketService: BasketService,
        private tableService: TableService,
    ) {
        this.canOpenAvailabilityPopover = this.sharedService.hasPermission('products-availability/*', 'GET');
    }

    ngOnInit() {
        // if (this.route.snapshot.url.length === 2 || this.route.snapshot.url.length === 4) {
        //     const purchaseCatalogueRoute = this.catalogueService.getRoute(CatalogueModes.PurchaseCatalogue);
        //     this.catalogueMode = this.route.snapshot.url[1].path === purchaseCatalogueRoute
        //         ? CatalogueModes.PurchaseCatalogue : CatalogueModes.Catalogue;
        // }
        // subscribe to router event
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            for (const key of Object.keys(params)) {
                this.currentQueryParams[key] = params[key];
            }
        });

        // get details
        this.catalogueService.getProductDetails(this.activatedRoute.snapshot.params.brand, this.detailId,
            this.catalogueMode)
            .subscribe(product => {
                this.product = product;
                this.productionGroup1 = this.product.productionGroupCode1;
                this.productionGroup2 = this.product.productionGroupCode2;
                this.asProductUrl = this.catalogueService.getAsProductUrl(product.asWebProductName, product.asWebUrlPart);
                console.log(product);
                console.log(this.asProductUrl);

                this.loadAvailability();
            }, err => console.log(err));


    }

    loadAvailability() {
        if (!this.canOpenAvailabilityPopover || this.isColumnHidden('futureDeliveryPeriod1_4')) {
            return;
        }
        // get availability
        this.busy = true;
        this.catalogueService.getProductAvailability(this.product.brandCode, this.detailId).subscribe(data => {
            this.availabilityRowsObj = data;
            this.busy = false;
        }, err => {
            this.busy = false;
            console.log(err)
        });
    }

    back() {
        this.location.back();
    }

    addToOrder() {
        this.basketService.addToOrder([
            {
                amountOrdered: this.amount.toString(),
                eCommProductId: this.product.eCommProductId,
                brandCode: this.product.brandCode,
                deliveryDate: ''
            }
        ])
            .subscribe(data => {
                this.amount = null;
            }, err => console.log(err));
    }

    getTotalPrice() {
        const price = Math.round((this.amount * this.product.unitPrice) * 100) / 100
        return price ? price : 0;
    }

    public isColumnHidden(columnName: string): boolean {
        return this.catalogueService.isColumnHidden(columnName, this.catalogueMode) ||
            this.tableService.isColumnSuppressed('catalogue', columnName);
    }
}
