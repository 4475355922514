import { Component } from "@angular/core";
import { ProjectPriceListService } from "./project-price-lists.service";

@Component({
  selector: "app-project-price-lists",
  templateUrl: "./project-price-lists.component.html",
})
export class ProjectPriceListsComponent {
  constructor(
    public projectPriceListsService: ProjectPriceListService, 
  ) {}
}
