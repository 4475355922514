/** import external modules **/
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient,
         HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app.routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { LocalNumberPipe, LocalCurrencyPipe, LocalDatePipe, LocalDateTimeFromNowPipe, CapitalizePipe } from './locale.pipes.module';
import { SanitizeHtmlPipe } from './sanitize-html.pipe'
import { FileUploadModule } from 'ng2-file-upload';

import { CanDeactivateDetail } from './detailGuard/detail.guard';

import { NgxFaviconModule } from 'ngx-favicon';
import { customFavicons, AvailableCustomFavicons } from './favicon.config';

/** import services **/
import { EcmHttpService } from './services/http/ecm.http.service';
import { PreloaderService } from './services/preloader.service';
import { WindowService } from './services/window.service';
import { createTranslateLoader, Translate } from './services/translate/translate.module';
import { LanguageService } from './services/language.service';
import { SharedService } from './services/shared.service';
import { CleanUpService } from './services/clean.up.service';
import { ToastService } from './services/toastService/toast.service';
import { AuthenticationService } from './services/authentication.service';
import { ParametersService } from './services/parameters.service';
import { FormService } from './services/form.service';
import { TableService } from './services/table.service';
import { UserService } from './services/user/user.service';
import { UsersAdminService } from './services/user/users.admin.service';
import { CustomersService } from './services/customer/customers.service';
import { CustomersGridService } from './services/customer/customers.grid.service';
import { DeliveryOrdersService } from './services/deliveryOrder/delivery.orders.service';
import { DeliveryOrdersGridService } from './services/deliveryOrder/delivery.orders.grid.service';
import { DeliveryOrdersItemsService } from './services/deliveryOrder/delivery.orders.items.service';
import { DeliveryOrdersItemsGridService } from './services/deliveryOrder/delivery.orders.items.grid.service';
import { DeliveryOrderGridService } from './services/deliveryOrder/delivery.order.grid.service';
import { InvoicesService } from './services/invoice/invoices.service';
import { InvoicesGridService } from './services/invoice/invoices.grid.service';
import { InvoicesItemsService } from './services/invoice/invoices.items.service';
import { InvoicesItemsGridService } from './services/invoice/invoices.items.grid.service';
import { InvoiceDetailGridService } from './services/invoice/invoice.detail.grid.service';
import { CatalogueService } from './services/catalogue/catalogue.service';
import { CatalogueGridService } from './services/catalogue/catalogue.grid.service';
import { AddToRfqService } from './components/catalogueComponent/addToRfqDialogComponent/add.to.rfq.dialog.service';
import { OrdersService } from './services/order/orders.service';
import { BasketService } from './services/basket/basket.service';
import { CurrentOrderGridService } from './services/basket/current.order.grid.service';
import { CustomerStoresAvailabilityGridService } from './services/basket/customer.stores.availability.grid.service';
import { OrdersItemsService } from './services/order/orders.items.service';
import { OrdersItemsGridService } from './services/order/orders.items.grid.service';
import { ShipmentService } from './services/shipment/shipment.service';
import { ShipmentGridService } from './services/shipment/shipment.grid.service';
import { ShipmentsService } from './services/shipment/shipments.service';
import { ShipmentsItemsService } from './services/shipment/shipments.items.service';
import { ShipmentsItemsGridService } from './services/shipment/shipments.items.grid.service';
import { LoginDialogService } from './components/login/loginDialogComponent/login.dialog.service';
import { RequireBusinessConditionsDialogService } from './components/login/requireBusinessConditionsDialogComponent/require.business.conditions.dialog.service';
import { QuestionDialogService } from './components/questionDialogComponent/question.dialog.service';
import { ImportBasketErrorDialogService } from './components/basket/importBasketErrorDialogComponent/import.basket.error.dialog.service';
import { CustomerStoresAvailabilityDialogService } from './components/basket/customerStoresAvailabilityComponent/customer.stores.availability.dialog.service';
import { SplitBasketItemDialogService } from './components/basket/splitBasketItemsDialogComponent/split.basket.item.dialog.service';
import { ImportShipmentErrorDialogService } from './components/shipment/importShipmentErrorDialogComponent/import.shipment.error.dialog.service';
import { OrderDialogService } from './components/order/orderDialogComponent/order.dialog.service';
import { ShipmentDialogService } from './components/shipment/shipmentDialogComponent/shipment.dialog.service';
import { DiscussionDialogService } from './components/discussion/discussionDialogComponent/discussion.dialog.service';
import { DiscountDialogService } from './components/discountDialogComponent/discount.dialog.service';
import { DiscussionService } from './services/discussion.service';
import { AttachmentDialogService } from './components/attachmentComponent/attachmentDialogComponent/attachment.dialog.service';
import { JournalService } from './services/journal/journal.service';
import { MonitoringService } from './services/monitoring/monitoring.service';
import { JournalGridService } from './services/journal/journal.grid.service';
import { NewsService } from './services/news/news.service';
import { NewsGridService } from './services/news/news.grid.service';
import { NewsDetailService } from './services/news/news.detail.service';
import { NewsDialogService } from './services/news/news.dialog.service';
import { NewsDiscussionDialogService } from './services/news/news.discussion.dialog.service';
import { NewsDetailGridService } from './services/news/news.detail.grid.service';
import { NewsReadDetailService } from './services/news/news.read.detail.service';
import { NewsReadDetailGridService } from './services/news/news.read.detail.grid.service';
import { MonitoringGridService } from './services/monitoring/monitoring.grid.service';
import { ControllingService } from './services/controlling/controlling.service';
import { ControllingGridService } from './services/controlling/controlling.grid.service';
import { APparamsService } from './services/ap.params.service';
import { RfqService } from './services/rfq/rfq.service';
import { RfqsService } from './services/rfq/rfqs.service';
import { RfqsGridService } from './services/rfq/rfqs.grid.service';
import { RfqsItemsGridService } from './services/rfq/rfqs.items.grid.service';
import { RfqGridService } from './services/rfq/rfq.grid.service';
import { RfqJournalGridService } from './services/rfq/rfq.journal.grid.service';
import { RfqPermissionService } from './services/rfq/rfq.permission.service';
import { PermissionsService } from './services/permissions.service';
import { RfqDetailSplitDialogService } from './components/rfqComponent/rfqDetailComponent/RfqDetailSplitDialogComponent/rfq.detail.split.dialog.service';
import { RfqDetailJournalDialogService} from './components/rfqComponent/rfqDetailComponent/RfqDetailJournalDialogComponent/rfq.detail.journal.dialog.service';
import { BusinessConditionsService } from './services/businessConditions/business.conditions.service';
import { StockGridService } from './components/stockComponent/stock.grid.service';
import { ImportStockErrorDialogService } from './components/stockComponent/importStockErrorDialogComponent/import.stock.error.dialog.service';
import { CustomerStockService } from './components/customerStockComponent/customer.stock.service';
import { ASStockService } from './components/aSStockComponent/as.stock.service';
import { ChatService } from './services/chat/chat.service';
import { HomepageService } from '@services/homepage.service';
import { PriceListService } from '@components/price-list/price-list.service';
import { PriceListsGridService } from '@app/components/price-list/price-lists/price-lists.grid.service';
import { PriceListsItemsGridService } from '@app/components/price-list/price-lists/price-lists-items.grid.service';
import { PriceListDetailGridService } from '@app/components/price-list/price-list-detail/price-list-detail.grid.service';
import { AddAsUserDialogService } from './components/usersAdminComponent/addAsUserDialogComponent/add.as.user.dialog.service';
import { SupplierService } from '@components/supplier/supplier.service';
import { SuppliersGridService } from '@components/supplier/suppliers/suppliers.grid.service';
import { PrfqsGridService } from '@app/components/prfq/prfqs/prfqs.grid.service';
import { PrfqService } from '@app/components/prfq/prfq.service';
import { PrfqsItemsService } from '@app/components/prfq/prfqs-items/prfqs-items.service';
import { PrfqsItemsGridService } from '@app/components/prfq/prfqs-items/prfqs-items.grid.service';
import { PrfqsDetailItemsGridService } from '@app/components/prfq/prfq-detail/prfqs-detail-items.grid.service';
import { PrfqJournalGridService } from '@app/components/prfq/prfq-detail/prfq.journal.grid.service';
import { ImportErrorDialogService } from '@components/import-error/import-error-dialog.service';
import { AutoLogoutService } from '@services/auto-logout/auto-logout.service';
import { CCheckService } from '@components/c-check/c-check.service';
import { CChecksGridService } from '@components/c-check/c-checks/c-checks.grid.service';
import { CCheckPurchaseOrderGridService } from '@components/c-check/c-check-purchase-order/c-check-purchase-order.grid.service';
import { CCheckDeliveryOrderGridService } from '@components/c-check/c-check-delivery-order/c-check-delivery-order.grid.service';
import { RfqCloseQuestionnaireDialogService } from '@components/rfqComponent/rfqDetailComponent/rfq-close-questionnaire/rfq-close-questionnaire.dialog.service';
import { SentEmailsListGridService } from '@components/sent-emails/sent-emails-list/sent-emails-list.grid.service';
import { SentEmailsService } from '@components/sent-emails/sent-emails.service';
import { SendByEmailDialogService } from '@components/send-by-email/send-by-email.dialog.service';
/** import components **/
import { AppComponent } from './app.component';
import { UnderConstructionComponent } from './components/underConstructionComponent/under.construction.component';
import { FooterComponent } from './components/footer/footerComponent/footer.component';
import { SearchComponent } from './components/searchComponent/search.component'
import { BusyIndicatorComponent } from './components/preloaderComponent/busyIndicatorComponent/busy.indicator.component';
import { PreloaderComponent } from './components/preloaderComponent/preloader.component';
import { DropdownComponent } from './components/dropdownComponent/dropdown.component';
import { MultiselectComponent } from '@components/multiselect/multiselect.component';
import { HomeComponent } from './components/homeComponent/home.component';
import { HomeAs1Component } from './components/homeComponent/as1/home-as1.component';
import { LoginComponent } from './components/login/loginComponent/login.component';
import { LoginDialog } from './components/login/loginDialogComponent/login.dialog.component';
import { RequireBusinessConditionsComponent } from './components/login/requireBusinessConditionsDialogComponent/require.business.conditions.dialog.component';
import { QuestionDialog } from './components/questionDialogComponent/question.dialog.component';
import { QuestionDialogWithComment } from './components/questionDialogComponent/question.dialog.with.comment.component';
import { ImportBasketErrorDialog } from './components/basket/importBasketErrorDialogComponent/import.basket.error.dialog.component';
import { ImportStockErrorDialogComponent } from './components/stockComponent/importStockErrorDialogComponent/import.stock.error.dialog.component';
import { SplitBasketItemDialogComponent } from './components/basket/splitBasketItemsDialogComponent/split.basket.item.dialog.component';
import { ImportShipmentErrorDialog } from './components/shipment/importShipmentErrorDialogComponent/import.shipment.error.dialog.component';
import { OrderDialog } from './components/order/orderDialogComponent/order.dialog.component';
import { ShipmentDialog } from './components/shipment/shipmentDialogComponent/shipment.dialog.component';
import { DiscussionDialog } from './components/discussion/discussionDialogComponent/discussion.dialog.component';
import { DiscountDialog } from './components/discountDialogComponent/discount.dialog.component';
import { AttachmetDialog } from './components/attachmentComponent/attachmentDialogComponent/attachment.dialog.component';
import { UserProfileComponent } from './components/userProfileComponent/user.profile.component';
import { UsersAdminComponent } from './components/usersAdminComponent/users.admin.component';
import { AddAsUserDialogComponent } from './components/usersAdminComponent/addAsUserDialogComponent/add.as.user.dialog.component';
import { CustomersComponent } from './components/customer/customersComponent/customers.component';
import { CustomerComponent } from './components/customer/customerComponent/customer.component';
import { DeliveryOrdersComponent } from './components/deliveryOrder/deliveryOrdersComponent/delivery.orders.component';
import { DeliveryOrdersItemsComponent } from './components/deliveryOrder/deliveryOrdersComponent/delivery.orders.items.component';
import { DeliveryOrderComponent } from './components/deliveryOrder/deliveryOrderComponent/delivery.order.component';
import { InvoicesComponent } from './components/invoice/invoicesComponent/invoices.component';
import { InvoicesItemsComponent } from './components/invoice/invoicesComponent/invoices.items.component';
import { InvoiceDetailComponent } from './components/invoice/invoiceDetailComponent/invoice.detail.component';
import { CatalogueComponent } from './components/catalogueComponent/catalogue.component';
import { AddToRfqDialogComponent } from './components/catalogueComponent/addToRfqDialogComponent/add.to.rfq.dialog.component';
import { FilterComponent } from './components/catalogueComponent/filterComponent/filter.component';
import { AccordionMenuComponent } from './components/accordionMenuComponent/accordion.menu.component';
import { TableComponent } from './components/tableComponent/table.component';
import { StickyHeaderComponent } from './components/tableComponent/stickyHeaderComponent/sticky.header';
import { TableFilterComponent } from './components/tableComponent/tableFilterComponent/table.filter.component';
import { TableSettingsComponent } from './components/tableComponent/tableSettingsComponent/table.settings.component';
import { TableSortComponent } from './components/tableComponent/tableSortComponent/table.sort.component';
import { PaginationComponent } from './components/tableComponent/paginationComponent/pagination.component';
import { ProductDetailComponent } from './components/productDetailComponent/product.detail.component';
import { OrdersSummaryComponent } from './components/order/ordersComponent/ordersSummaryComponent/orders.summary.component';
import { OrdersItemsComponent } from './components/order/ordersComponent/ordersItemsComponent/orders.items.component';
import { OrderDetailComponent } from './components/order/orderDetailComponent/order.detail.component';
import { ShipmentsComponent } from './components/shipment/shipmentsComponent/shipments.component';
import { ShipmentsItemsComponent } from './components/shipment/shipmentsComponent/shipments.items.component';
import { ShipmentDetailComponent } from './components/shipment/shipmentDetailComponent/shipment.detail.component';
import { CurrentOrderComponent } from './components/basket/currentOrderComponent/current.order.component';
import { CurrentOrderProductsAndDetailComponent } from './components/basket/currentOrderProductsAndDetailComponent/current.order.products.and.detail.component';
import { CustomerStoresAvailabilityDialogComponent } from './components/basket/customerStoresAvailabilityComponent/customer.stores.availability.dialog.component';
import { GridDropdownRendererComponent} from './components/tableComponent/gridRendererComponents/gridDropdownRendererComponent/grid.dropdown.renderer.component';
import { GridDateEditorComponent } from './components/tableComponent/gridEditorComponents/gridDateEditorComponent/grid.date.editor.component';
import { GridDateRendererComponent } from './components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridTranslateRendererComponent } from './components/tableComponent/gridRendererComponents/gridTranslateRendererComponent/grid.translate.renderer.component';
import { GridSelectRendererComponent } from './components/tableComponent/gridRendererComponents/gridSelectRendererComponent/grid.select.renderer.component';
import { GridSelectEditorComponent } from './components/tableComponent/gridEditorComponents/gridSelectEditorComponent/grid.select.editor.component';
import { GridSelectedRendererComponent } from './components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridLinkRendererComponent } from './components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridNumberRendererComponent } from './components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridPriceRendererComponent } from './components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridRowPriceRendererComponent } from './components/tableComponent/gridRendererComponents/gridRowPriceRendererComponent/grid.row.price.renderer.component';
import { GridRowWeightRendererComponent } from './components/tableComponent/gridRendererComponents/gridRowWeightRendererComponent/grid.row.weight.renderer.component';
import { GridInputRendererComponent } from './components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridInputEditorComponent } from './components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { GridPlusRendererComponent } from './components/tableComponent/gridRendererComponents/gridPlusRendererComponent/grid.plus.renderer.component';
import { GridPlusClickRendererComponent } from './components/tableComponent/gridRendererComponents/gridPlusClickRendererComponent/grid.plus.click.renderer.component';
import { GridStateRendererComponent } from './components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { GridAvailabilityRendererComponent } from './components/tableComponent/gridRendererComponents/gridAvailabilityRendererComponent/grid.availability.renderer.component';
import { GridActionsRendererComponent } from './components/tableComponent/gridRendererComponents/gridActionsRendererComponent/grid.actions.renderer.component';
import { GridEditableRowActionsRendererComponent } from './components/tableComponent/gridRendererComponents/gridEditableRowActionsRendererComponent/grid.editable.row.actions.renderer.component'
import { GridHeaderRendererComponent } from './components/tableComponent/gridRendererComponents/gridHeaderRendererComponent/grid.header.renderer.component';
import { GridHeaderSelectAllRendererComponent } from './components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { GridHeaderActionsRendererComponent } from './components/tableComponent/gridRendererComponents/gridHeaderActionsRendererComponent/grid.header.actions.renderer.component';
import { GridHeaderActions2RendererComponent } from './components/tableComponent/gridRendererComponents/gridHeaderActions2RendererComponent/grid.header.actions2.renderer.component';
import { GridCommentRendererComponent } from './components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridAttachmentRendererComponent } from './components/tableComponent/gridRendererComponents/gridAttachmentRendererComponent/grid.attachment.renderer.component';
import { GridCatalogueAvailabilityRendererComponent } from './components/tableComponent/gridRendererComponents/gridCatalogueAvailabilityRendererComponent/grid.catalogue.availability.renderer.component';
import { GridUrlLinkRendererComponent } from './components/tableComponent/gridRendererComponents/gridUrlLinkRendererComponent/grid.url.link.renderer.component';
import { GridMailtoRendererComponent } from './components/tableComponent/gridRendererComponents/gridMailtoRendererComponent/grid.mailto.renderer.component';
import { GridOnDeliveryOrderRendererComponent } from './components/tableComponent/gridRendererComponents/gridOnDeliveryOrderRendererComponent/grid.on.delivery.order.renderer.component';
import { GridOrderItemDeliveryDateHistoryRendererComponent } from './components/tableComponent/gridRendererComponents/gridOrderItemDeliveryDateHistoryRendererComponent/grid.order.item.delivery.date.history.renderer.component';
import { GridRfqActionsRendererComponent } from './components/tableComponent/gridRendererComponents/gridRfqActionsRendereComponent/grid.rfq.actions.renderer.component';
import { GridNewsActionsRendererComponent } from './components/tableComponent/gridRendererComponents/gridNewsActionsRendereComponent/grid.news.actions.renderer.component';
import { GridRfqDateOfferedRendererComponent } from './components/tableComponent/gridRendererComponents/gridRfqDateOfferedRendererComponent/grid.rfq.date.offered.renderer.component';
import { GridTypeaheadRendererComponent } from './components/tableComponent/gridRendererComponents/gridTypeaheadRendererComponent/grid.typeahead.renderer.component';
import { GridTypeaheadEditorComponent } from './components/tableComponent/gridEditorComponents/gridTypeaheadEditorComponent/grid.typeahead.editor.component';
import { GridJournalAdditionalInfoRendererComponent } from './components/tableComponent/gridRendererComponents/gridJournalAdditionalInfoRendererComponent/grid.journal.additional.info.renderer.component';
import { GridDistributorStoresActionsRendererComponent} from './components/tableComponent/gridRendererComponents/gridDistributorStoresActionsRendereComponent/grid.distributor.stores.actions.renderer.component';
import { GridNewsShowInDialogRendererComponent } from './components/tableComponent/gridRendererComponents/gridNewsShowInDialogActionRendereComponent/grid.news.show.in.dialog.renderer.component';
import { GridGenericRowActionsRendererComponent } from '@components/tableComponent/gridRendererComponents/gridGenericRowActionsRendererComponent/grid.generic.row.actions.renderer.component';

import { DiscussionComponent } from './components/discussion/discussionComponent/discussion.component';
import { SupportComponent } from './components/footer/supportComponent/support.component';
import { ContactComponent } from './components/footer/contactComponent/contact.component';
import { ManualsComponent } from './components/footer/manualsComponent/manuals.component';
import { SecurityComponent } from './components/footer/securityComponent/security.component';
import { FaqComponent } from './components/footer/faqComponent/faq.component';
import { BusinessConditionsComponent } from './components/footer/businessConditionsComponent/business.conditions.component';
import { JournalComponent } from './components/journalComponent/journal.component';
import { NewsComponent } from './components/newsComponent/news.component';
import { NewsDetailComponent } from './components/newsComponent/newsDetailComponent/news.detail.component';
import { NewsDialogComponent } from './components/newsComponent/newsDialogComponent/news.dialog.component';
import { NewsDiscussionDialogComponent } from './components/newsComponent/newsDiscussionDialogComponent/news.discussion.dialog.component';
import { NewsDiscussionComponent } from './components/newsComponent/newsDiscussionComponent/news.discussion.component';
import { NewsReadDetailDialogComponent} from './components/newsComponent/newsReadDetailDialogComponent/news.read.detail.dialog.component'
import { WysiwygComponent } from './components/wysiwygComponent/wysiwyg.component';
import { MonitoringComponent } from './components/monitoringComponent/monitoring.component';
import { ControllingComponent } from './components/controlling/controllingComponent/controlling.component';
import { AttachmentComponent } from './components/attachmentComponent/attachment.component';
import { JournalDetailComponent } from './components/journalDetailComponent/journal.detail.component';
import { RfqsSummaryComponent } from './components/rfqComponent/rfqsComponent/rfqsSummaryComponent/rfqs.summary.component';
import { RfqsItemsComponent } from './components/rfqComponent/rfqsComponent/rfqsItemsComponent/rfqs.items.component';
import { RfqDetailComponent } from './components/rfqComponent/rfqDetailComponent/rfq.detail.component';
import { RfqDetailAddItemsComponent } from './components/rfqComponent/rfqDetailComponent/rfq.detail.add.items.component';
import { RfqDetailItemsComponent } from './components/rfqComponent/rfqDetailComponent/rfq.detail.items.component';
import { RfqImportDetailErrorDialogComponent } from './components/rfqComponent/rfqDetailComponent/RfqImportDetailErrorDialogComponent/rfq.import.detail.error.dialog.component';
import { RfqDetailQuestionnaireComponent} from './components/rfqComponent/rfqDetailComponent/RfqDetailQuestionnaireComponent/rfq.detail.questionnaire.component';
import { RfqDetailNoteComponent} from './components/rfqComponent/rfqDetailComponent/RfqDetailNoteComponent/rfq.detail.note.component';
import { RfqDetailSplitDialogComponent } from './components/rfqComponent/rfqDetailComponent/RfqDetailSplitDialogComponent/rfq.detail.split.dialog.component';
import { RfqDetailJournalDialogComponent } from './components/rfqComponent/rfqDetailComponent/RfqDetailJournalDialogComponent/rfq.detail.journal.dialog.component';
import { StockComponent } from './components/stockComponent/stock.component';
import { CustomerStockComponent } from './components/customerStockComponent/customer.stock.component';
import { ASStockComponent } from './components/aSStockComponent/as.stock.component';
import { CopyrightComponent } from '@components/footer/footerComponent/CopyrightComponent/copyright.component';
import { FooterLinksComponent } from '@app/components/footer/footerComponent/FooterLinksComponent/footer.links.component';
import { SubcustomerComponent } from '@components/usersAdminComponent/subcustomerComponent/subcustomer.component';
import { CustomerStockOptionsComponent } from '@components/customerStockComponent/customerStockOptionsComponent/customer.stock.options.component';
import { BaseComponent } from '@components/base/base.component';
import { PriceListImportComponent } from '@components/price-list/price-list-import/price-list-import.component';
import { FileLoaderComponent } from '@app/components/file-loader/file-loader.component';
import { PasteComponent } from '@components/paste/paste.component';
import { PriceListImportErrorDialogComponent } from '@components/price-list/price-list-import/price-list-import-error-dialog.component';
import { SuppliersComponent } from '@components/supplier/suppliers/suppliers.component';
import { SupplierDetailComponent } from '@components/supplier/supplier-detal/supplier-detail.component';
import { PrfqsComponent } from '@app/components/prfq/prfqs/prfqs.component';
import { PrfqsItemsComponent } from '@app/components/prfq/prfqs-items/prfqs-items.component';
import { PrfqDetailComponent } from '@app/components/prfq/prfq-detail/prfq-detail.component';
import { PrfqDetailItemsComponent } from '@app/components/prfq/prfq-detail/prfq-detail-items.component';
import { PrfqDetailAddItemsComponent } from '@components/prfq/prfq-detail/items-import/prfq-detail-add-items.component';
import { PrfqDetailUpdateItemsComponent } from '@components/prfq/prfq-detail/items-import/prfq-detail-update-items.component';
import { UserRoleSelectComponent } from '@components/user-role-select/user-role-select.component';
import { NewPostComponent } from '@components/discussion/newPostComponent/new-post.component';
import { ClosePrfqComponent } from '@components/prfq/prfq-detail/close-prfq/close-prfq.component';
import { YearRangePickerComponent } from '@components/tableComponent/tableFilterComponent/year-range-picker/year-range-picker.component';
import { CChecksComponent} from '@components/c-check/c-checks/c-checks.component';
import { CCheckPurchaseOrderComponent} from '@components/c-check/c-check-purchase-order/c-check-purchase-order.component';
import { CCheckDeliveryOrderComponent} from '@components/c-check/c-check-delivery-order/c-check-delivery-order.component';
import { RfqCloseQuestionnaireComponent } from '@components/rfqComponent/rfqDetailComponent/rfq-close-questionnaire/rfq-close-questionnaire.component';
import { RfqCloseQuestionnaireDialogComponent } from '@components/rfqComponent/rfqDetailComponent/rfq-close-questionnaire/rfq-close-questionnaire.dialog.component';
import { SentEmailsListComponent } from '@components/sent-emails/sent-emails-list/sent-emails-list.component';
import { PresentationsComponent } from '@components/footer/presentations-component/presentations.component';
import { AdminMenuComponent } from '@components/admin-menu/admin-menu.component';
import { SendByEmailDialogComponent } from '@components/send-by-email/send-by-email-component/send-by-email.dialog.component';
import { ProjectPriceListsComponent } from '@components/project-price-lists/project-price-lists.component';

/** import directives **/
import { PermissionHideDirective } from './directives/permission.hide.directive';
import { OnlyNumberDirective } from './directives/only.number.directive';
import { ValidPasswordDirective } from './directives/valid.password.directive';
import { ValidConfirmPasswordDirective } from './directives/valid.confirm.password.directive';
import { ValidateUniqueDirective } from './directives/validate.unique.directive';
import { FocusDirective } from './directives/myfocus.directive';
import { NumberInputComponent } from './components/genericComponent/numberInputComponent/number.input.component';
import { NewsDetailIframeComponent } from './components/newsComponent/newsDetailComponent/news.detail.iframe.component';
import { LogoutComponent } from './components/logoutComponent/logout.component';
import { PriceListsComponent } from './components/price-list/price-lists/price-lists.component'
import { PriceListsItemsComponent } from './components/price-list/price-lists/price-lists-items.component'
import { PriceListDetailComponent } from '@components/price-list/price-list-detail/price-list-detail.component';
import { PrfqPermissionService } from './components/prfq/prfq.permission.service';
import { NoteComponent } from './components/note-component/note.component';
import { ImportErrorComponent } from './components/import-error/import-error.component';
@NgModule({
  imports:      [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule .forRoot(),
    PopoverModule.forRoot(),
    TimepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        } }),
    ToastrModule.forRoot(),
    FormsModule,
    AgGridModule.withComponents([
        // RedComponentComponent
    ]),
    NgxPageScrollCoreModule.forRoot({duration: 300}),
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    MyDatePickerModule,
    FileUploadModule,
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'favicon.ico',
      custom: customFavicons
    })
  ],
  providers: [
    Title,
    PreloaderService,
    WindowService,
    ToastService,
    SharedService,
    CleanUpService,
    LanguageService,
    AuthenticationService,
    ParametersService,
    EcmHttpService,
    FormService,
    TableService,
    Translate,
    UserService,
    UsersAdminService,
    CustomersService,
    CustomersGridService,
    DeliveryOrdersService,
    DeliveryOrdersGridService,
    DeliveryOrdersItemsService,
    DeliveryOrdersItemsGridService,
    DeliveryOrderGridService,
    InvoicesService,
    InvoicesGridService,
    InvoicesItemsService,
    InvoicesItemsGridService,
    InvoiceDetailGridService,
    CatalogueService,
    CatalogueGridService,
    AddToRfqService,
    OrdersService,
    BasketService,
    CurrentOrderGridService,
    CustomerStoresAvailabilityGridService,
    OrdersItemsService,
    OrdersItemsGridService,
    ShipmentService,
    ShipmentGridService,
    ShipmentsService,
    ShipmentsItemsService,
    ShipmentsItemsGridService,
    LoginDialogService,
    RequireBusinessConditionsDialogService,
    QuestionDialogService,
    ImportBasketErrorDialogService,
    CustomerStoresAvailabilityDialogService,
    SplitBasketItemDialogService,
    ImportShipmentErrorDialogService,
    OrderDialogService,
    ShipmentDialogService,
    DiscussionDialogService,
    DiscussionService,
    DiscountDialogService,
    AttachmentDialogService,
    JournalService,
    MonitoringService,
    JournalGridService,
    NewsService,
    NewsGridService,
    NewsDetailService,
    NewsDetailGridService,
    NewsReadDetailService,
    NewsReadDetailGridService,
    NewsDialogService,
    NewsDiscussionDialogService,
    MonitoringGridService,
    ControllingService,
    ControllingGridService,
    APparamsService,
    RfqService,
    RfqsService,
    RfqsGridService,
    RfqsItemsGridService,
    RfqGridService,
    RfqJournalGridService,
    RfqPermissionService,
    PermissionsService,
    RfqDetailSplitDialogService,
    RfqDetailJournalDialogService,
    BusinessConditionsService,
    StockGridService,
    ImportStockErrorDialogService,
    CustomerStockService,
    ASStockService,
    ChatService,
    CanDeactivateDetail,
    HomepageService,
    PriceListService,
    PriceListsGridService,
    PriceListsItemsGridService,
    PriceListDetailGridService,
    AddAsUserDialogService,
    SupplierService,
    SuppliersGridService,
    PrfqsGridService,
    PrfqsItemsGridService,
    PrfqsDetailItemsGridService,
    PrfqJournalGridService,
    PrfqsItemsService,
    PrfqService,
    PrfqPermissionService,
    ImportErrorDialogService,
    AutoLogoutService,
    CCheckService,
    CChecksGridService,
    CCheckPurchaseOrderGridService,
    CCheckDeliveryOrderGridService,
    RfqCloseQuestionnaireDialogService,
    SentEmailsListGridService,
    SentEmailsService,
    SendByEmailDialogService,
  ],
  declarations: [
    AppComponent,
    UnderConstructionComponent,
    FooterComponent,
    SearchComponent,
    PreloaderComponent,
    BusyIndicatorComponent,
    DropdownComponent,
    MultiselectComponent,
    HomeComponent,
    HomeAs1Component,
    LoginComponent,
    LoginDialog,
    RequireBusinessConditionsComponent,
    QuestionDialog,
    QuestionDialogWithComment,
    ImportBasketErrorDialog,
    ImportStockErrorDialogComponent,
    SplitBasketItemDialogComponent,
    ImportShipmentErrorDialog,
    OrderDialog,
    ShipmentDialog,
    DiscussionDialog,
    DiscountDialog,
    AttachmetDialog,
    UserProfileComponent,
    UsersAdminComponent,
    AddAsUserDialogComponent,
    CustomersComponent,
    CustomerComponent,
    DeliveryOrdersComponent,
    DeliveryOrdersItemsComponent,
    DeliveryOrderComponent,
    InvoicesComponent,
    InvoicesItemsComponent,
    InvoiceDetailComponent,
    CatalogueComponent,
    AddToRfqDialogComponent,
    FilterComponent,
    AccordionMenuComponent,
    TableComponent,
    StickyHeaderComponent,
    TableFilterComponent,
    TableSettingsComponent,
    TableSortComponent,
    PaginationComponent,
    ProductDetailComponent,
    OrdersSummaryComponent,
    OrdersItemsComponent,
    OrderDetailComponent,
    ShipmentsComponent,
    ShipmentsItemsComponent,
    ShipmentDetailComponent,
    CurrentOrderComponent,
    CurrentOrderProductsAndDetailComponent,
    CustomerStoresAvailabilityDialogComponent,
    GridDateRendererComponent,
    GridTranslateRendererComponent,
    GridDropdownRendererComponent,
    GridSelectRendererComponent,
    GridSelectEditorComponent,
    GridSelectedRendererComponent,
    GridLinkRendererComponent,
    GridNumberRendererComponent,
    GridPriceRendererComponent,
    GridRowWeightRendererComponent,
    GridDateEditorComponent,
    GridRowPriceRendererComponent,
    GridPlusRendererComponent,
    GridPlusClickRendererComponent,
    GridStateRendererComponent,
    GridAvailabilityRendererComponent,
    GridActionsRendererComponent,
    GridEditableRowActionsRendererComponent,
    GridInputRendererComponent,
    GridInputEditorComponent,
    GridHeaderRendererComponent,
    GridHeaderSelectAllRendererComponent,
    GridHeaderActionsRendererComponent,
    GridHeaderActions2RendererComponent,
    GridCommentRendererComponent,
    GridAttachmentRendererComponent,
    GridCatalogueAvailabilityRendererComponent,
    GridUrlLinkRendererComponent,
    GridMailtoRendererComponent,
    GridOnDeliveryOrderRendererComponent,
    GridOrderItemDeliveryDateHistoryRendererComponent,
    GridRfqActionsRendererComponent,
    GridNewsActionsRendererComponent,
    GridRfqDateOfferedRendererComponent,
    GridTypeaheadEditorComponent,
    GridTypeaheadRendererComponent,
    GridJournalAdditionalInfoRendererComponent,
    GridGenericRowActionsRendererComponent,
    DiscussionComponent,
    DiscountDialog,
    SupportComponent,
    ContactComponent,
    ManualsComponent,
    SecurityComponent,
    FaqComponent,
    BusinessConditionsComponent,
    JournalComponent,
    NewsComponent,
    NewsDetailComponent,
    NewsDialogComponent,
    NewsDiscussionDialogComponent,
    NewsDiscussionComponent,
    NewsReadDetailDialogComponent,
    MonitoringComponent,
    PermissionHideDirective,
    OnlyNumberDirective,
    ValidPasswordDirective,
    ValidConfirmPasswordDirective,
    ValidateUniqueDirective,
    FocusDirective,
    LocalNumberPipe, LocalCurrencyPipe, LocalDatePipe, LocalDateTimeFromNowPipe, CapitalizePipe,
    SanitizeHtmlPipe,
    ControllingComponent,
    AttachmentComponent,
    JournalDetailComponent,
    RfqsSummaryComponent,
    RfqsItemsComponent,
    RfqDetailComponent,
    RfqDetailAddItemsComponent,
    RfqDetailItemsComponent,
    RfqDetailNoteComponent,
    RfqImportDetailErrorDialogComponent,
    RfqDetailQuestionnaireComponent,
    RfqDetailSplitDialogComponent,
    RfqDetailJournalDialogComponent,
    NumberInputComponent,
    WysiwygComponent,
    StockComponent,
    CustomerStockComponent,
    ASStockComponent,
    GridDistributorStoresActionsRendererComponent,
    NewsDetailIframeComponent,
    GridNewsShowInDialogRendererComponent,
    LogoutComponent,
    CopyrightComponent,
    FooterLinksComponent,
    SubcustomerComponent,
    CustomerStockOptionsComponent,
    PriceListsComponent,
    PriceListsItemsComponent,
    PriceListDetailComponent,
    BaseComponent,
    PriceListImportComponent,
    FileLoaderComponent,
    PasteComponent,
    PriceListImportErrorDialogComponent,
    SuppliersComponent,
    SupplierDetailComponent,
    PrfqsComponent,
    PrfqsItemsComponent,
    PrfqDetailComponent,
    PrfqDetailItemsComponent,
    NoteComponent,
    PrfqDetailAddItemsComponent,
    PrfqDetailUpdateItemsComponent,
    ImportErrorComponent,
    UserRoleSelectComponent,
    NewPostComponent,
    ClosePrfqComponent,
    YearRangePickerComponent,
    CChecksComponent,
    CCheckPurchaseOrderComponent,
    CCheckDeliveryOrderComponent,
    RfqCloseQuestionnaireComponent,
    RfqCloseQuestionnaireDialogComponent,
    SentEmailsListComponent,
    PresentationsComponent,
    AdminMenuComponent,
    SendByEmailDialogComponent,
    ProjectPriceListsComponent,
  ],
  bootstrap:    [ AppComponent ]
})
export class AppModule {}
