import { ProjectPriceList } from "@app/model/customer.model";

export interface ProjectPriceListsOptions {
  userPreferencePrefix: ProjectPriceListsUserPreferencePrefix;
  // If true, the product sale price list will be shown in the dropdown (if allowed by AS params)
  showProductSalePriceListCode: boolean;
}

export enum ProjectPriceListsUserPreferencePrefix {
  Basket = 'currentOrder_currentPriceList',
  Catalogue = 'catalogue_currentPriceList',
}

export interface ProjectPriceListDropdownItem extends Partial<ProjectPriceList> {
  id: number | null;
  name: string;
}
