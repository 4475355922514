import { Injectable } from '@angular/core';
import { ProjectPriceListDropdownItem, ProjectPriceListsOptions, ProjectPriceListsUserPreferencePrefix } from './project-price-lists.types';
import { SharedService } from '@app/services/shared.service';
import { Subject } from 'rxjs';
import { UserService } from '@app/services/user/user.service';

@Injectable()
export class ProjectPriceListService {
  // Project priceList dropdown data
  public projectPriceLists: ProjectPriceListDropdownItem[] = [];
  // Currently selected priceList (priceListCode == undefined means the General priceList)
  public currentPriceList: ProjectPriceListDropdownItem;
  private onPriceListSelectedSubject: Subject<string> = new Subject<string>();
  public onPriceListSelected$ = this.onPriceListSelectedSubject.asObservable();
  private options: ProjectPriceListsOptions;

  constructor(
    private sharedService: SharedService,
    private userService: UserService,
  ) {
    this.projectPriceLists = this.getProjectPriceLists();
    this.currentPriceList = this.sharedService.user.preferences[this.options?.userPreferencePrefix] ||
      this.projectPriceLists[0];
  }

  /**
   * Must be called by the host component, best in the component's constructor
   */
  public init(options: ProjectPriceListsOptions): void {
    this.options = options;

    this.projectPriceLists = this.getProjectPriceLists();

    this.currentPriceList = this.sharedService.user.preferences[this.options.userPreferencePrefix] ||
      this.projectPriceLists[0];
  }

  public onSelectProjectPriceList(event: { selected: ProjectPriceListDropdownItem}): void {
    this.setProjectPriceList(event.selected);
    this.onPriceListSelectedSubject.next(this.currentPriceList.priceListCode);
  }

  private setProjectPriceList(projectPriceList: ProjectPriceListDropdownItem): void {
    this.currentPriceList = projectPriceList;
    this.userService.setUserPreference(this.options?.userPreferencePrefix, this.currentPriceList, false);
  }

  private getProjectPriceLists(): ProjectPriceListDropdownItem[] {
    return [
      {
        id: null,
        name: this.sharedService.translateService.instant(
            "GENERAL_PROJECT_PRICE_LIST"
        ),
      },
      ...this.sharedService.user.representsCustomer.projectPriceLists.map(
        (item) => Object.assign({}, item, { name: item.priceListName })
      ),
      // Product sale pricelist
      ...(
        this.sharedService.params.features.catalogue?.productSalePriceListCode &&
        this.options?.showProductSalePriceListCode
        ? [
            {
              id: -1, // just to be unique
              name: this.sharedService.translateService.instant("PRODUCT_SALE"),
              priceListCode:
                this.sharedService.params.features.catalogue.productSalePriceListCode,
              priceListCurrency:
                this.sharedService.user.representsCustomer.priceListCurrency,
            },
          ]
        : []
      ),
    ];
  }

  public isSetNonGeneralProjectPriceList(): boolean {
    return this.currentPriceList && this.currentPriceList.priceListCode !== undefined;
  }

  public setGeneralProjectPriceList(): void {
    // Other then generel pricelist is set
    if (this.isSetNonGeneralProjectPriceList()) {
      const general = this.projectPriceLists.find(item => typeof item.priceListCode === 'undefined');
      if (!general) {
        console.error('General project price list not found');
        return;
      }
      this.setProjectPriceList(general);
    }
  }

  public removeProjectPriceList(priceListCode: string): void {
    this.currentPriceList = this.projectPriceLists[0];
    this.projectPriceLists = this.projectPriceLists.filter(item => item.priceListCode !== priceListCode);

    this.sharedService.user.representsCustomer.projectPriceLists =
      this.sharedService.user.representsCustomer.projectPriceLists.filter(item => item.priceListCode !== priceListCode);
  }
}
